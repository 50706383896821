import React, {
	useEffect,
	useContext
} from "react";

import { useLottie } from "lottie-react";
import spinner from '../../assets/spinner/spinner.json';
import IE_Spinner from '../../assets/spinner/IE_Spinner.json';

import {StoreContext} from "../../store/store";

// Action types
import {
    ERROR
} from "../../constants/actionTypes";

import {isIE} from "../../utils/Utils";

// Stying
import "./loading.scss";

const Loading = props => {    
	const globalState = useContext(StoreContext);
	const { dispatch } = globalState;

	const lottieSpinner = isIE() ? IE_Spinner : spinner;
	const options = {
		animationData: lottieSpinner,
		loop: true,
		autoplay: true,
	};

	const { View } = useLottie(options);

	useEffect(() => {
		let errorTimeout = setTimeout(() => {
			dispatch({
				type: ERROR
			});
		}, process.env.REACT_APP_ENV_LOADING_SPINNER_TIMEOUT);

		return () => {
			clearTimeout(errorTimeout);
		};

	}, []);

	return (
        <>
            <div className="vf-loading-wrapper">
                <div className="vf-loading">
					<div className="vf-loading-icon">
						{View}
					</div>
                </div>
            </div>
        </>
	);
}

export default Loading;
