import React, {
    useEffect,
    useContext,
    useState
} from "react";

import {
    StoreContext
} from "../../store/store";

// Stying
import "./template.scss";

const Validate = props => {
    const globalState = useContext(StoreContext);
    const { dispatch } = globalState;

    const context = globalState.state && globalState.state.data && globalState.state.data.context ? globalState.state.data.context : "";
    const brandtmpl = globalState.state.brandtmpl.replace("authorize_", "");

    return (
        <div className={"template " + brandtmpl + " " + context}>
            {props.children}
        </div>
    );
}

export default Validate;
