import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";

// importing i18n here is needed for using <Trans... in components
// import i18n from "./i18n";

import Template from "./components/Template/Template";
import Loading from "./components/Loading/Loading";
const App = lazy(() => import("./App"));

import { CookiesProvider } from 'react-cookie';
import { StateProvider } from "./store/store";

import * as serviceWorker from "./serviceWorker";

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

const loadApp = () => {
	// Load App
	console.log("");
	console.log("——— App ————————————");
	console.log("App loaded");
	console.log("App version: " + process.env.REACT_APP_VERSION);

	root.render(
		<CookiesProvider>
			<StateProvider>
				<Template>
					<Suspense fallback={<Loading />}>
						<App />
					</Suspense>
				</Template>
			</StateProvider>	
		</CookiesProvider>
	);
}

loadApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();